import React from "react";

function Lyric(props) {
    const { data, currentTime = 0 } = props;
    
    const timeline = data && data.timeline ? data.timeline : [];
    const searchItemByTime = function(ms) {
        var i = timeline.length;
        while(i--) {
            if(ms > timeline[i].time) {
                return timeline[i];
            }
        }
        return null;
    };

    const item = searchItemByTime(currentTime * 1000);

    //过滤 .trc, .xtrc 逐字精准
    const content = item ? (item.content).replace(/<\d+>/g, "") : "";
    const xtrans = item ? item.xtrans : "";

    return (
        <div className="lyric">
            <div className="x-content">{content}</div>
            <div className="x-trans">{xtrans}</div>
        </div>
    )
}

function propsAreEqual(props, nextProps) {
    return (
        props.data === nextProps.data &&
        props.currentTime === nextProps.currentTime
    );
}

export default React.memo(Lyric, propsAreEqual);