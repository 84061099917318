import React from "react";

function Playlist(props) {
    const { list = [], selectedItemId, onItemClick } = props;

    return (
        <div className="playlist" style={{ marginTop: props.sticky + 'px'}}>
            <ul>
                {list.map(item => {
                    const selected = item.id === selectedItemId;

                    return (
                        <li
                            key={item.id}
                            className={selected ? "playlist-item selected" : "playlist-item"}
                            onClick={() => {
                                onItemClick && onItemClick(item.id);
                            }}
                        >
                            <span className="label-title">{item.title}</span>
                            <span className="label-artist">{item.artist}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function propsAreEqual(props, nextProps) {
    return (
        props.selectedItemId === nextProps.selectedItemId &&
        props.list === nextProps.list &&
        props.sticky === nextProps.sticky
    );
}

export default React.memo(Playlist, propsAreEqual);
