import React, { createContext, useEffect, useMemo, useState } from "react";

// Simple object which we use to set and  identify the pages in our router 
export const pagesMapping = {
    home: "home",
    player: "player"
}

export const RoutingContext = createContext({ page: pagesMapping.home });

function Router({ children }) {

    // Read the urlPath, e.g. '/about' or '/'
    // const path = window.location.pathname.slice(1).toLowerCase();
    const path = window.location.hash.slice(2).toLowerCase().replace(/\?.*/, "");
    
    // Set the default page to Home if not specified otherwise in the URL
    const [ page, setPage ] = useState(path)
  
    const value = useMemo(() => {
        return { 
            page, 
            setPage
        }
    }, [ page, setPage ]);

    useEffect(() => {

        const handleHashChange = () => {
            const path = window.location.hash.slice(2).toLowerCase().replace(/\?.*/, "");
            setPage(path);
        };
        window.addEventListener("hashchange", handleHashChange);

        return  () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);
    
    return (
        <RoutingContext.Provider value={value}>
            {children}
        </RoutingContext.Provider>
    )
}

export default Router;