import React, { useContext } from "react";

import Player from "./components/PlayerContainer.jsx";
import Albumlist from "./components/AlbumlistContainer.jsx";
import { pagesMapping, RoutingContext } from './Routing.jsx';

// --- Stylesheets ---
import "./styles/css/global.css";
import "./styles/css/app.css";
import "./styles/css/albums.css";

function App() {

    const { page } = useContext(RoutingContext);
    
    return (
        <div className="app">
            {(pagesMapping.home === page || "" === page) && (<Albumlist />)}
            {(pagesMapping.player === page) && (<Player />)}
        </div>
    );
}

export default App;
