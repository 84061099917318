import React from "react";
import Controls from "./Controls.jsx";
import Playlist from "./Playlist.jsx";
import Visualizer from "./Visualizer.jsx";
import Lyric from "./Lyric.jsx";

function Player(props) {
    return (
        <div className="player">
            <Visualizer 
                data={props.vizData}
                type={props.vizType}               
                cover={props.cover}
                title={props.title}
                onClick={props.onVisualizerClick}
            />
            {props.lrcData && (
                <Lyric 
                    data={props.lrcData}
                    currentTime={props.currentTime}
                />
            )}
            <Controls 
                sticky={props.sticky}
                list={props.list} 
                selectedItemId={props.selectedItemId}
                isPlaying={props.isPlaying}
                currentTime={props.currentTime}
                totalTime={props.totalTime}
                onPlayClick={props.onPlay}
                onPauseClick={props.onPause}
                onNextClick={props.onNext}
                onPrevClick={props.onPrev}
                onPositionChange={props.onSeek}
            />
            <Playlist 
                sticky={props.sticky}
                list={props.list} 
                selectedItemId={props.selectedItemId}
                onItemClick={props.onSelect}
            />
        </div>
    );
}

export default Player;
