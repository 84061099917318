var eventThrottler = function(handler, delay) {
    var eventTimeout;

    return function(...params) {
        if(!eventTimeout) {
            eventTimeout = setTimeout(function() {
                eventTimeout = null;

                handler(...params);

            }, delay)
        }
    }
};

export default eventThrottler