import React from "react";
import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import "../../styles/css/slider.css";

// default values
const MIN_VALUE = 0;
const MAX_VALUE = 100;

// utils
const map = function(value, fromLow, fromHigh, toLow, toHigh) {
    return (toHigh - toLow) * (value / (fromHigh - fromLow)) + toLow;
};

/**
 * Horizontal Slider
 */
function HSlider(props) {

    const [isDragging, setIsDragging] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [minPositionX, setMinPositionX] = useState(0);
    const [maxPositionX, setMaxPositionX] = useState(0);

    const baseRef = useRef(null);
    const thumbRef = useRef(null);

    const { 
        value = 0, 
        onChange, 
        min = MIN_VALUE, 
        max = MAX_VALUE, 
        disabled = false 
    } = props;

    useEffect(() => {
        // initialize
        const updateThumbRange = () => {
            const base = baseRef.current;
            const thumb = thumbRef.current;
            setMinPositionX(0);
            setMaxPositionX(base.offsetWidth - thumb.offsetWidth);
        };

        updateThumbRange();
        window.addEventListener("resize", updateThumbRange);

        // finalize
        return () => {
            window.removeEventListener("resize", updateThumbRange);
        }

    }, []);

    const handleDragStart = (event, data) => {
        const posX = map(value, min, max, minPositionX, maxPositionX);

        setIsDragging(true);
        setPositionX(posX);
        setPositionY(0);
    };

    const handleDragStop = (event, data) => {
        const posX = data.x;

        setIsDragging(false);
        setPositionX(posX);

        const updatedValue = map(posX, minPositionX, maxPositionX, min, max);
        onChange && onChange(updatedValue);
    };

    const handleDragMove = (event, data) => {
        const posX = data.x;

        setPositionX(posX);
    };
    
    const x = isDragging ? positionX : map(value, min, max, minPositionX, maxPositionX);
    const y = positionY;

    return (
        <div className="slider horizontal" ref={baseRef}>
            <div className="slider-track"></div>
            <Draggable
                axis="x"
                bounds="parent"
                position={{ x: x, y: y }}
                onStart={handleDragStart}
                onStop={handleDragStop}
                onDrag={handleDragMove}
                disabled={disabled}
            >
                <div className="slider-thumb" ref={thumbRef}>
                    <span></span>
                </div>
            </Draggable>
        </div>
    );
}

/**
 * Vertical Slider
 */
function VSlider(props) {

    const [isDragging, setIsDragging] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [minPositionY, setMinPositionY] = useState(0);
    const [maxPositionY, setMaxPositionY] = useState(0);

    const baseRef = useRef(null);
    const thumbRef = useRef(null);

    const { 
        value = 0, 
        onChange, 
        min = MIN_VALUE, 
        max = MAX_VALUE, 
        disabled = false 
    } = props;

    useEffect(() => {
        // initialize
        const updateThumbRange = () => {
            const base = baseRef.current;
            const thumb = thumbRef.current;
            setMinPositionY(0);
            setMaxPositionY(base.offsetHeight - thumb.offsetHeight);
        };

        updateThumbRange();
        window.addEventListener("resize", updateThumbRange);

        // finalize
        return () => {
            window.removeEventListener("resize", updateThumbRange);
        }

    }, []);

    const handleDragStart = (event, data) => {
        const posY = map(value, min, max, minPositionY, maxPositionY);

        setIsDragging(true);
        setPositionX(0);
        setPositionY(posY);
    };

    const handleDragStop = (event, data) => {
        const posY = data.y;

        setIsDragging(false);
        setPositionY(posY);

        const updatedValue = map(posY, minPositionY, maxPositionY, min, max);
        onChange && onChange(updatedValue);
    };

    const handleDragMove = (event, data) => {
        const posY = data.y;

        setPositionY(posY);
    };

    const x = positionX;
    const y = isDragging ? positionY : map(value, min, max, minPositionY, maxPositionY);

    return (
        <div className="slider vertical" ref={baseRef}>
            <div className="slider-track"></div>
            <Draggable
                axis="y"
                bounds="parent"
                position={{ x: x, y: y }}
                onStart={handleDragStart}
                onStop={handleDragStop}
                onDrag={handleDragMove}
                disabled={disabled}
            >
                <div className="slider-thumb" ref={thumbRef}>
                    <span></span>
                </div>
            </Draggable>
        </div>
    );
}

/**
 * Slider
 */
function Slider(props) {
    switch(props.type) {
        case "horizontal":
            return <HSlider {...props} />

        case "vertical":
            return <VSlider {...props} />

        default:
            return <input {...props} type="range" onChange={(event) => { props.onChange && props.onChange(event.target.value) }} className="slider native" />
    }
};

export default Slider;
