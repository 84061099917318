var lrcParser = {
    parse: function(str) {

        var info = {
            meta: {title:"", artist:"", album:"", by:""},
            timeline: [],
            offset: 0
        };

        // .xtrc
        var preTimelines = [];

        var timeline = info.timeline;

        //replace all multiple line breaks with a single new line character
        str = str.replace(/[\r\n]+/g, '\n');

        var lines = str.split('\n');

        //range：[00:00.00X]～[59:59.99X]
        var pattern = /\[[0-5][0-9]:[0-5][0-9].\d+\]/g;

        var numLines = lines.length;
        for(var i = 0; i < numLines; ++i)
        {
            var line = lines[i];

            var timeTags = line.match(pattern);
            var numTimeTags = timeTags ? timeTags.length : 0;

            if(numTimeTags > 0) {
                //---TIME Tags---

                // .xtrc
                preTimelines.length = 0;

                //get lyric content
                var lyric = line.substr(line.lastIndexOf(']')+1);

                for(var j = 0; j < numTimeTags; ++j)
                {
                    var timeTag = timeTags[j];

                    var sec = parseInt(timeTag.substr(1,2)) * 60 + parseFloat(timeTag.substring(4,timeTag.length-1));

                    var ms = sec * 1000;

                    var tl = {time: ms, content: lyric};
                    timeline[timeline.length] = tl;

                    // .xtrc
                    preTimelines.push(tl);
                }
            }
            else {
                //trim
                var trimmedLine = line.replace(/^\s*|\s*$/g, '');

                var indexOfColon = trimmedLine.indexOf(':');
                if(indexOfColon >= 0) {

                    //---ID Tags---

                    var idTag = trimmedLine;
                    var key = idTag.substring(1, indexOfColon);
                    var value = idTag.substring(indexOfColon+1, idTag.length-1);

                    switch(key) {
                        case 'ti':
                            info.meta['title'] = value;
                            break;
                        case 'ar':
                            info.meta['artist'] = value;
                            break;
                        case 'al':
                            info.meta['album'] = value;
                            break;
                        case 'by':
                            info.meta['by'] = value;
                            break;
                        case 'offset':
                            info.offset = parseInt(value);
                            break;
                        default:
                            break;
                    }
                }
                else {

                    //---Others---

                    // .xtrc
                    var match = trimmedLine.match(/\[x-trans\](.*)/);
                    if(match) {
                        var translatedContent = match[1];

                        // preTimelines.forEach(function(tl) {
                        //     tl.xtrans = translatedContent;
                        // });

                        var numTimelines = preTimelines.length;
                        for(var k = 0; k < numTimelines; k++) {
                            preTimelines[k].xtrans = translatedContent;
                        }
                    }

                }
            }
        }

        //sort
        var field = "time";
        timeline.sort(function(a, b) {
            if(a[field] < b[field]) return -1;
            else if(a[field] > b[field]) return 1;
            return 0;
        });

        //apply offset
        var num = timeline.length;
        for(i=0; i < num; ++i) {
            timeline[i].time += info.offset;
        }

        return info;
    }
};

export default lrcParser;