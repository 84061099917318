import { requestGet } from "./common/requestHelper";

// conditional compilation based on Webpack configuration
const isDevelopment =
    typeof process !== "undefined" && process.env.NODE_ENV === "development";

const DEV = "https://xiami-1305151140.cos.ap-guangzhou.myqcloud.com";
const PROD = "https://xiami-1305151140.cos.ap-guangzhou.myqcloud.com";
const BASE_URL = isDevelopment ? DEV : PROD;

var cachedSongList = [];

const api = {
    fetchAlbum: function(id) {
        return new Promise((resolve, reject) => {
            requestGet({
                url: BASE_URL + "/album/" + id + "/data.json",
                success: (data) => {
                    // cached data
                    cachedSongList = data.songs;

                    resolve(data);
                },
                error: reject
            });
        });
    },

    fetchCollect: function(id) {
        return new Promise((resolve, reject) => {
            requestGet({
                url: BASE_URL + "/collect/" + id + "/data.json",
                success: (data) => {
                    // cached data
                    cachedSongList = data.songs;

                    resolve(data);
                },
                error: reject
            });
        });
    },

    fetchArtist: function(id, page = 1, pageSize = 60) {
        // return new Promise((resolve, reject) => {
        //     requestGet({
        //         url: BASE_URL + "/artist/" + id,
        //         data: { page: page, pageSize: pageSize },
        //         success: resolve,
        //         error: reject
        //     });
        // });
        return Promise.reject(new Error("not implement"));
    },

    fetchSong: function(id) {
        // return new Promise((resolve, reject) => {
        //     requestGet({
        //         url: BASE_URL + "/song/" + id,
        //         success: resolve,
        //         error: reject
        //     });
        // });
        return Promise.reject(new Error("not implement"));
    },

    fetchSongs: function(songIds) {
        // return new Promise((resolve, reject) => {
        //     requestGet({
        //         url: BASE_URL + "/songs/" + songIds.join(","),
        //         success: resolve,
        //         error: reject
        //     });
        // });
        return Promise.reject(new Error("not implement"));
    },

    getAlbumCoverURL: function(albumId) {
        return BASE_URL + "/album/" + albumId + "/cover.jpg";
    },

    getCollectCoverURL: function(collectId) {
        return BASE_URL + "/collect/" + collectId + "/cover.jpg";
    },

    getArtistCoverURL: function(collectId) {
        return BASE_URL + "/artist/" + collectId + "/cover.jpg";
    },

    getSongURL: function(songId) {
        const songInfo = cachedSongList.find(item => item.id === songId);
        if(songInfo) {
            return BASE_URL + "/album/" + songInfo.albumStringId + "/" + songId + ".mp3"; 
        }
        return "";
    },

    loadLyric: function(songId, url) {
        return new Promise((resolve, reject) => {
            const songInfo = cachedSongList.find(item => item.id === songId);
            if(songInfo) {
                requestGet({
                    url: BASE_URL + "/album/" + songInfo.albumStringId + "/" + songId + ".lrc",
                    contentType: "text/plain",
                    success: resolve,
                    error: reject
                }); 
            }
            else {
                reject(new Error("songId not in the playlist."))
            }
        });
    },

    search: function(key) {
        // return new Promise((resolve, reject) => {
        //     requestGet({
        //         url: BASE_URL + "/search",
        //         data: { key: key },
        //         success: resolve,
        //         error: reject
        //     });
        // });
        return Promise.reject(new Error("not implement"));
    },

    fetchAlbums: function() {
        return new Promise((resolve, reject) => {
            requestGet({
                url: BASE_URL + "/albums.json",
                success: resolve,
                error: reject
            });
        });
    },

    fetchCollects: function() {
        return new Promise((resolve, reject) => {
            requestGet({
                url: BASE_URL + "/collects.json",
                success: resolve,
                error: reject
            });
        });
    }
};

export default api;
