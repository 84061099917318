import React from "react";
import { IoIosArrowDropdown, IoIosInformationCircleOutline } from "react-icons/io";

function Albumlist(props) {

    const { list = [], onItemClick, onItemCollapse } = props;

    return (
        <div className="albumlist">
            <ul>
                {list.map(item => {
                    return (
                        <li
                            key={item.id}
                            className="albumlist-item"
                            onClick={() => {
                                onItemClick && onItemClick(item);
                            }}
                        >
                            <div className="title">{item.title}</div>
                            <div>
                                <span className="label-icon">
                                    <img src={item.cover} alt={item.title} />
                                </span>
                                {item.description && (
                                    <button 
                                        key={item.id}
                                        className="button-more"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onItemCollapse && onItemCollapse(item);
                                        }}
                                    >
                                        {item.collapse ? (
                                            <IoIosInformationCircleOutline className="icon-caret-right" />
                                        ) : (
                                            <IoIosArrowDropdown className="icon-caret-down" />
                                        )}
                                    </button>
                                )}
                                <span className={item.collapse ? "label-desc collapse" : "label-desc"}>
                                    { 
                                        item.description
                                            .replace(/\r\n/g, '\n')
                                            .replace(/<br>/g, '\n')
                                            .replace(/<br \/>/g, '\n')
                                            .replace(/<div>/g, '')
                                            .replace(/<\/div>/g, '\n')
                                            .replace(/<p>/g, '')
                                            .replace(/<\/p>/g, '\n')
                                            .replace(/&nbsp;/g, ' ')
                                            .replace(/&quot;/g, '"')
                                            .replace(/&hellip;/g, '…')
                                    }
                                </span>
                                {/* 
                                <span className="label-desc" dangerouslySetInnerHTML={{__html: item.description}}>
                                </span> 
                                */}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}


function propsAreEqual(props, nextProps) {
    return (
        props.list === nextProps.list
    );
}

export default React.memo(Albumlist, propsAreEqual);