import request from "superagent";

const getTimestamp = function() {
    return Date.now();
};

const preventCache = false;

// 异步数据请求时 http header 默认设置 Content-Type 为 application/json
//
// 注意事项
// 开发过程中，若要跨域请求时带上 cookie 需要将 xhr.withCredentials 设为 true
// 为兼容 IE 浏览器，所有 GET 请求都需要带一个随机数或时间戳的参数，
// 以防止 IE 下 GET 请求相同的 URL 时只会读取缓存内容，不获取新数据

export function requestGet(options) {
    const { url, data, success, error } = options;
    const contentType = options.contentType || "application/json";
    const q = preventCache ? { ...data, t: getTimestamp() } : data;
    const textOnly = contentType.indexOf("text/") !== -1;

    request
        .get(url)
        //.withCredentials()
        .set("Content-Type", contentType)
        .query(q)
        .then(res => {
            success && success(textOnly ? res.text : res.body);
        })
        .catch(err => {
            error && error(err);
        });
}

export function requestPost(options) {
    const { url, data, success, error } = options;
    const contentType = options.contentType || "application/json";
    const textOnly = contentType.indexOf("text/") !== -1;

    request
        .post(url)
        //.withCredentials()
        .set("Content-Type", contentType)
        .send(data)
        .then(res => {
            success && success(textOnly ? res.text : res.body);
        })
        .catch(err => {
            error && error(err);
        });
}

export function requestPut(options) {
    const { url, data, success, error } = options;
    const contentType = options.contentType || "application/json";
    const textOnly = contentType.indexOf("text/") !== -1;

    request
        .put(url)
        //.withCredentials()
        .set("Content-Type", contentType)
        .send(data)
        .then(res => {
            success && success(textOnly ? res.text : res.body);
        })
        .catch(err => {
            error && error(err);
        });
}

export function requestDelete(options) {
    const { url, data, success, error } = options;
    const contentType = options.contentType || "application/json";
    const textOnly = contentType.indexOf("text/") !== -1;

    request
        .delete(url)
        //.withCredentials()
        .set("Content-Type", contentType)
        .send(data)
        .then(res => {
            success && success(textOnly ? res.text : res.body);
        })
        .catch(err => {
            error && error(err);
        });
}

export function requestMultipart(options) {
    const { url, data, success, error } = options;
    var file = data.file;
    var progress = options.progress;

    if (progress === undefined) {
        progress = function(event) {
            console.log("uploading..." + Math.round(event.percent) + "%");
        };
    }

    const req = request
        .post(url)
        //.withCredentials()
        .attach("file", file, file.name || "noname")
        .on("progress", progress)
        .then(res => {
            success && success(res.body);
        })
        .catch(err => {
            error && error(err);
        });

    return req;
}