import React from "react";
import { useEffect, useRef } from "react";
import eventThrottler from "../utils/eventThrottler";

var peakValues = [];
for(var i=0; i<128; i++) peakValues[i] = 0;

function Visualizer(props) {

    const canvasRef = useRef();

    useEffect(() => {
        
        const resizeCanvas = function() {
            // resize canvas
            const canvas = canvasRef.current;
            const parent = canvas.parentNode;
            canvas.width = parent.offsetWidth;
            canvas.height = parent.offsetHeight;
        }

        const onResize = eventThrottler(function() {
            resizeCanvas();
        }, 250);

        resizeCanvas();
        window.addEventListener("resize", onResize);

        // finalize
        return () => {
            window.removeEventListener("resize", onResize);
        }

    }, []);

    useEffect(() => {
        if(props.data.length === 0) return;

        // simplified data
        const data = props.data.filter((e, i) => i % 8 === 0);
        const dataLength = data.length;

        // get type of visualization
        const type = props.type;
        
        // get canvas referrence
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // get current canvas size
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        if(dataLength > 0) {
            const ox = canvasWidth - 512 >> 1;

            // lock
            ctx.save();

            
            if(type === "spectrum") {
                // use clear
                ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            } 
            else {
                // use fade
                var imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
                var buffer = imageData.data;
                var bufferLength = buffer.length;
                for (var i = 0; i < bufferLength; i += 4) {
                    // buffer[i + 0] // R
                    // buffer[i + 1] // G
                    // buffer[i + 2] // B
                    buffer[i + 3] *= 0.65; // A
                }
                ctx.putImageData(imageData, 0, 0);
            }            

            // draw bars
            var j, barHeight;
            if(type === "spectrum") {
                ctx.fillStyle = "rgba(128, 128, 128, 0.5)";
                for (j = 0; j < dataLength; j++) {
                    barHeight = Math.round(data[j] * canvasHeight * 0.5);
                    ctx.fillRect(
                        j * 4 + 1 + ox,
                        canvasHeight - barHeight,
                        2,
                        barHeight
                    );
                        
                    // update peak values
                    peakValues[j] = Math.max(peakValues[j] - 0.005, data[j]);
                }

                ctx.fillStyle = "rgba(128, 128, 128, 0.75)";
                for (j = 0; j < dataLength; j++) {
                    barHeight = Math.round(peakValues[j] * canvasHeight * 0.5);
                    ctx.fillRect(
                        j * 4 + 1 + ox,
                        canvasHeight - barHeight - 2,
                        2,
                        2
                    );
                }
            } 
            else {
                ctx.fillStyle = "rgba(128, 128, 128, 0.5)";
                for (j = 0; j < dataLength; j++) {
                    barHeight = Math.round(data[j] * canvasHeight * 0.5);
                    ctx.fillRect(
                        j * 4 + 1 + ox,
                        (canvasHeight - barHeight) - canvasHeight * 0.5,
                        2,
                        barHeight * 2
                    );
                }
            }

            // unlock
            ctx.restore();
        }
        else {
            // clear                  
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        }

    });

    const title = props.title ? props.title.split("\n").map((str, i) => (<p key={i}>{str}</p>)) : "";

    return (
        <div className="visualizer" onClick={props.onClick}>
            <div className="cover" style={{ backgroundImage: `url(${props.cover})` }}></div>
            <canvas ref={canvasRef} width="512" height="256"></canvas>
            <div className="title marquee">
                <span>{title}</span>
            </div>
        </div>
    );
};

function propsAreEqual(props, nextProps) {
    return (
        props.data === nextProps.data &&
        props.type === nextProps.type &&
        props.cover === nextProps.cover &&
        props.title === nextProps.title 
    );
}

export default React.memo(Visualizer, propsAreEqual);
