import React from "react";
import { IoIosPlay, IoIosPause, IoIosRewind, IoIosFastforward } from "react-icons/io";
import Slider from "./common/Silder.jsx";
import formatSeconds from "../utils/formatSeconds";

const PlaybackPosition = React.memo(
    (props) => {
        const { currentTime = 0, totalTime = 0, onPositionChange } = props;

        const position = totalTime > 0 ? Math.round((currentTime / totalTime) * 10000) : 0;

        const handleSliderChange = (value) => {
            onPositionChange && onPositionChange((value / 10000).toFixed(4));
        };

        return (
            <div className="playback-position">
                <span className="label-position">
                    {`${formatSeconds(currentTime)} / ${formatSeconds(totalTime)}`}
                </span>
                <Slider
                    type="horizontal"
                    min={0}
                    max={10000}
                    value={position}
                    onChange={handleSliderChange}
                />
            </div>
        );
    },
    (props, nextProps) => {
        return (
            props.currentTime === nextProps.currentTime &&
            props.totalTime === nextProps.totalTime
        );
    }
);

const PlaybackButtons = React.memo(
    (props) => {

        const { list, selectedItemId, onPauseClick, onPlayClick, onPrevClick, onNextClick } = props;

        const handlePrevClick = () => {
            var target = list.find((item, i, arr) => (arr[i + 1] && arr[i + 1].id === selectedItemId));
            if(!target) target = list[list.length - 1];
            onPrevClick && onPrevClick(target.id);
        };

        const handleNextClick = () => {
            var target = list.find((item, i, arr) => (arr[i - 1] && arr[i - 1].id === selectedItemId));
            if(!target) target = list[0];
            onNextClick && onNextClick(target.id);
        };

        const handlePlayClick = () => {
            var target = list.find((item) => (item.id === selectedItemId));
            if(!target) target = list[0];
            onPlayClick && onPlayClick(target.id);
        };

        return (
            <div className="playback-buttons">
                <button className="button-prev">
                    <IoIosRewind className="icon-prev" onClick={handlePrevClick} />
                </button>
                {props.isPlaying ? (
                    <button className="button-pause" onClick={onPauseClick}>
                        <IoIosPause className="icon-pause" />
                    </button>
                ) : (
                    <button className="button-play" onClick={handlePlayClick}>
                        <IoIosPlay className="icon-play" />
                    </button>
                )}
                <button className="button-next" onClick={handleNextClick}>
                    <IoIosFastforward className="icon-next" />
                </button>
            </div>
        );
    },
    (props, nextProps) => {
        return (
            props.isPlaying === nextProps.isPlaying &&
            props.list === nextProps.list &&
            props.selectedItemId === nextProps.selectedItemId 
        );
    }
);

function Controls(props) {
    return (
        <div className={props.sticky > 0 ? "controls sticky" : "controls"}>
            <PlaybackPosition {...props} />
            <PlaybackButtons {...props} />
        </div>
    );
}

export default Controls;
