/**
 * Mitt: Tiny functional event emitter / pubsub.
 * https://github.com/developit/mitt
 * modified by civet 20191125
 */
var mitt = function(all) {
    all = all || Object.create(null);
    return {
        on: function(type, handler) {
            (all[type] || (all[type] = [])).push(handler);
        },

        off: function(type, handler) {
            if (all[type]) {
                all[type].splice(all[type].indexOf(handler) >>> 0, 1);
            }
        },

        emit: function(type) {
            var args = Array.prototype.slice.call(arguments, 1);
            (all[type] || []).slice().forEach(function(handler) { handler.apply(this, args); });
        },

        removeAllListeners: function(type) {
            type ? all[type] = [] : all = Object.create(null);
        }
    };
};

var AudioPlayer = function() {

    //<audio preload="none"></audio>
    var audio = new Audio();
    audio.preload = 'none';
    audio.crossOrigin = 'anonymous';

    var play = function(url) {
        audio.src = url;
        audio.load();

        audio.addEventListener('progress', onProgress);
        audio.addEventListener('error', onError);
        audio.addEventListener('canplay', onCanPlay);
        audio.addEventListener('canplaythrough', onCanPlayThrough);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('ended', onEnded);
        return audio.play();
    };

    var pause = function() {
        audio.pause();
    };

    var stop = function() {
        if(audio.readyState === 0) return;

        try {
            audio.pause();
            audio.currentTime = 0;
        }
        catch(err) {
            console.log(err.message);
        }
    };

    var resume = function() {
        if(audio.paused) return audio.play();
        return Promise.reject("audio is not ready");
    };

    var seek = function(t) {
        audio.currentTime = t;
        return audio.currentTime;
    };

    var emitter = mitt();

    var on = function(type, handler) {
        emitter.on(type, handler);
    };

    var off = function(type, handler) {
        emitter.off(type, handler);
    };
    
    var removeAllListeners = function(type) {
        emitter.removeAllListeners(type);
    };

    function onProgress(event) {
        if(audio.readyState === 0 || audio.buffered.length === 0) return;

        var bufferedTimeRanges = audio.buffered;
        var duration = audio.duration;
        emitter.emit('progress', bufferedTimeRanges, duration);
    }

    function onError(event) {
        emitter.emit('error', event);
    }

    function onCanPlay(event) {
        emitter.emit('canplay', event);
    }

    function onCanPlayThrough(event) {
        emitter.emit('canplaythrough', event);
    }

    function onTimeUpdate(event) {
        if(!audio) return;
        if(audio.readyState === 0) return;

        var currentTime = audio.currentTime;
        var totalTime = audio.duration;
        emitter.emit('timeupdate', currentTime, totalTime);
    }

    function onEnded(event) {
        emitter.emit('ended', event);
    }

    return {
        play: play,
        pause: pause,
        stop: stop,
        resume: resume,
        seek: seek,
        on: on,
        off: off,
        removeAllListeners: removeAllListeners,
        dispose: function() { audio.pause(); audio = null; emitter = null; },
        isPaused: function() { return audio.paused; },
        getAudioSource: function() { return audio.src; },
        getAudio: function() { return audio; }
    };

};

export default AudioPlayer;