import React from "react";
import { useState, useEffect, useRef } from "react";
import VisualizerMicro from "visualizer-micro";
import Player from "./Player.jsx";
// import api from "../services/musicAPI";
import api from "../services/bucketAPI";
import AudioPlayer from "../utils/AudioPlayer";
import lrcParser from "../utils/lrcParser";
import getQueryParams from "../utils/getQueryParams";

function PlayerContainer(props) {

    const [list, setList] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [cover, setCover] = useState("");
    const [title, setTitle] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [vizType, setVisualizationType] = useState("waveform");
    const [vizData, setVisualizationData] = useState([]);
    const [lrcData, setLyricData] = useState(null);
    const [sticky, setSticky] = useState(0);

    const playerRef = useRef();
    const visualizerRef = useRef();
    const timerRef = useRef();
    const spectrumRef = useRef(vizType === "spectrum");

    useEffect(() => {   
        // --- initialize

        const player = new AudioPlayer();
        const visualizer = new VisualizerMicro();
        playerRef.current = player;
        visualizerRef.current = visualizer;        

        player.on("progress", function(buffered, duration) {
            //console.log(buffered, duration);            
            var ranges = [];
            for (var i = 0; i < buffered.length; i++) {
                var from = Math.floor(buffered.start(i) / duration) * 100;
                var to = Math.floor(buffered.end(i) / duration) * 100;
                ranges.push([from, to]);
            }            
            console.log("loading... " + ranges);
        });
        player.on("error", function() {
            console.log("error");
        });
        player.on("canplay", function() {
            console.log("canplay");
        });
        player.on("timeupdate", function(c, t) {
            //console.log(c, t);
            setCurrentTime(c);
            setTotalTime(t);
        });
        player.on("ended", function() {
            console.log("ended");
            setIsPlaying(false);
            
            // auto play next one
            // simulates a mouse click on buttonNext
            var btn = document.querySelector(".button-next");
            if(btn) btn.click();
        });
    
        const hash = window.location.hash;
        const searchString = hash.substring(hash.indexOf("?"));
        const query = getQueryParams( searchString );
        const url = query["url"] || "";
        const albumMatch = url.match(/album\/(\w+)/);
        const collectMatch = url.match(/collect\/(\w+)/);
        const artistMatch = url.match(/artist\/(\w+)/);

        if(albumMatch) {
            const albumId = albumMatch[1];
            
            api.fetchAlbum(albumId)
                .then(data => {
                    setList(data.songs);
                    //setCover(data.cover);
                    setCover(api.getAlbumCoverURL(albumId));
                })
                .catch(error => {
                    console.log(error.message);
                    alert(error.message);
                });
        }
        else if(collectMatch) {
            const collectId = collectMatch[1];
            
            api.fetchCollect(collectId)
                .then(data => {
                    setList(data.songs);
                    //setCover(data.cover);
                    setCover(api.getCollectCoverURL(collectId));
                })
                .catch(error => {
                    console.log(error.message);
                    alert(error.message);
                });

            // temp: download missing album covers
            // api.downloadCollectCovers(collectId)
            //     .then(data => {
            //         // console.log(data);
            //     })
            //     .catch(error => {
            //         console.log(error.message);
            //         alert(error.message);
            //     });
        }
        else if(artistMatch) {
            const artistId = artistMatch[1];

            api.fetchArtist(artistId)
                .then(data => {
                    setList(data.songs);
                    setCover(api.getArtistCoverURL(artistId));
                })
                .catch(error => {
                    console.log(error.message);
                    alert(error.message);
                });
        }

        const controls = document.querySelector(".controls");
        const controlsTop = controls ? controls.offsetTop : 0;
        const controlsHeight = controls ? controls.offsetHeight : 0;
        const onScroll = () => {
            if(controls) {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                if(scrollTop >= controlsTop) {
                    setSticky( controlsHeight );
                } else {
                    setSticky( 0 );
                }
            }
        };

        window.addEventListener("scroll", onScroll);
        
        return () => {
            // --- finalize

            window.addEventListener("scroll", onScroll);

            stopVisualizer();
            visualizer.unload();
            player.dispose();
        }

    }, []);

    useEffect(() => {
        // load lyric when selectedItem changed
        if(list.length === 0 || !selectedItemId) {
            setLyricData(null);
        }
        else {
            const selectedItem = list.find(item => item.id === selectedItemId);

            if(selectedItem) {
                const subTitle = selectedItem.subTitle ? " (" + selectedItem.subTitle + ")" : "";
                const fullTitle = "[ " + selectedItem.album + " ]　　" + selectedItem.title + subTitle + " - " + selectedItem.artist;
                setTitle(fullTitle);
            }
            else {
                setTitle("");
            }

            if(selectedItem && selectedItem.lyric) {
                api.loadLyric(selectedItem.id, selectedItem.lyric)
                    .then(data => {
                        if(data) {
                            const lrcInfo = lrcParser.parse(data);
                            setLyricData(lrcInfo);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        alert(error.message);
                    });
            }
            else {
                setLyricData(null);
            }
        }

    }, [list, selectedItemId]);

    const initVisualizer = function(callback) {
        const player = playerRef.current;
        const visualizer = visualizerRef.current;

        if (visualizer.isSupported()) {
            if(!visualizer.alreadyLoaded) {
                // Before you can call load(), the user has to click something.
                // load() only needs to be called once per audio source. 
                // It is not necessary to call it again if audio.src is changed.
                visualizer.load(player.getAudio(), function() {
                    callback && callback(null, true);
                });
            } else {
                callback && callback("visualizer is already initialized", false);
            }        
        } else {
            callback && callback("visualizer is not supported", false);
        }
    };

    const startVisualizer = function(/* useSpectrum = false */) {
        const player = playerRef.current;
        const visualizer = visualizerRef.current;

        if (visualizer.alreadyLoaded) {

            timerRef.current = setInterval(() => {
                if (player.isPaused()) return;

                // get visualization data at this moment
                var useSpectrum = spectrumRef.current;
                var data = useSpectrum
                    ? visualizer.getSpectrum()
                    : visualizer.getWaveform();
                
                // update state
                setVisualizationData(data);
                
            }, 40);
            
        } else {
            initVisualizer((err, success) => {
                if(success) {
                    startVisualizer(/* useSpectrum */);
                } else {
                    console.log(err);
                } 
            });
        }
    };

    const stopVisualizer = function() {
        if(timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    };

    const restartVisualizer = function() {
        stopVisualizer();
        startVisualizer();
    };

    const open = function(url) {
        const player = playerRef.current;
        player
            .play(url)
            .then(() => {
                console.log("playing...");
                setIsPlaying(true);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleSelect = function(songId) {
        // select
        setSelectedItemId(songId);

        // start playing
        var songURL = api.getSongURL(songId);    
        open(songURL);
        
        // start visualizer
        // [[[Attention]]] visualizer requires user click before calling load() method
        restartVisualizer();
    };

    const handlePlay = function(songId) {
        const player = playerRef.current;
        
        if(!player.getAudioSource()) {
            handleSelect(songId);
            return;
        }
        
        player
            .resume()
            .then(() => {
                console.log("resuming...");
                setIsPlaying(true);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handlePause = function() {
        const player = playerRef.current;
        player.pause();
        setIsPlaying(false);
    };

    const handleSeek = function(p) {
        const player = playerRef.current;
        const currentTime = player.seek(totalTime * p);
        setCurrentTime(currentTime);
    };

    const handlePrev = function(songId) {
        handleSelect(songId);
    };

    const handleNext = function(songId) {
        handleSelect(songId);
    };
    
    const handleVisualizerClick = function() {
        console.log('toggle viz type');
        spectrumRef.current = !spectrumRef.current;
    
        const useSpectrum = spectrumRef.current;
        setVisualizationType(useSpectrum ? "spectrum" : "waveform");
    }
    
    const stateToProps = {
        isPlaying: isPlaying,
        currentTime: currentTime,
        totalTime: totalTime,
        list: list,
        selectedItemId: selectedItemId,
        cover: cover,
        title: title,
        vizType: vizType,
        vizData: vizData,
        lrcData: lrcData,
        sticky: sticky
    };

    const handlersToProps = {
        onSelect: handleSelect,
        onPlay: handlePlay,
        onPause: handlePause,
        onSeek: handleSeek,
        onPrev: handlePrev,
        onNext: handleNext,
        onVisualizerClick: handleVisualizerClick
    };

    return (
        <Player {...stateToProps} {...handlersToProps} />
    );
}

export default PlayerContainer;
