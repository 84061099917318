var getQueryParams = function(searchString) {
    searchString = searchString || window.location.search;

    var query = {};

    if (searchString) {
        var parts = searchString.substring(1).split('&');

        for (var i = 0; i < parts.length; i++) {
            var nv = parts[i].split('=');
            if (!nv[0]) continue;
            query[nv[0]] = nv[1] || true;
        }
    }

    return query;
}

export default getQueryParams;