import React, { useState, useEffect } from "react";
import Albumlist from "./Albumlist.jsx";
// import api from "../services/musicAPI";
import api from "../services/bucketAPI";
import getQueryParams from "../utils/getQueryParams";

// const htmlTagsPattern = /<("[^"]*"|'[^']*'|[^'">])*>/g;

function AlbumlistContainer(props) {

    const [list, setList] = useState([]);

    useEffect(() => {   
        // --- initialize

        var q = getQueryParams();
        if(q.type === "collect") {

            api.fetchCollects()
                .then(collects => {

                    const collectlist = collects.map(collect => {
                        return {
                            id: collect.id,
                            title: collect.name,
                            description: collect.description, // ? collect.description.replace(htmlTagsPattern, "") : "",
                            url: `/player?url=/collect/${collect.id}/`,
                            cover: api.getCollectCoverURL(collect.id),
                            collapse: true
                        }
                    });
                    setList(collectlist);
                    
                })
                .catch(error => {
                    console.log(error.message);
                    alert(error.message);
                });
        
        }
        else {
            api.fetchAlbums()
                .then(albums => {

                    const albumlist = albums.map(album => {
                        return {
                            id: album.id,
                            title: album.name,
                            description: album.description, // ? album.description.replace(htmlTagsPattern, "") : "",
                            url: `/player?url=/album/${album.id}/`,
                            cover: api.getAlbumCoverURL(album.id),
                            collapse: true
                        }
                    });
                    setList(albumlist);
                    
                })
                .catch(error => {
                    console.log(error.message);
                    alert(error.message);
                });
        }

        return () => {
            // --- finalize

        }

    }, []);

    const handleItemClick = (item) => {
        const url = item.url;
        window.location = "#" + url;
    };

    const handleItemCollapse = (targetItem) => {
        const updatedList = list.map(item => {
            if(item.id === targetItem.id) {
                return {...item, collapse: !item.collapse}
            }
            return item;
        });

        setList(updatedList);
    };

    return (
        <Albumlist list={list} onItemClick={handleItemClick} onItemCollapse={handleItemCollapse} />
    );
}

export default AlbumlistContainer;