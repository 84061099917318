var formatSeconds = function(value) {
    var v = parseFloat(value.toPrecision(12));
    var h = Math.floor(v / 3600);
    var m = Math.floor((v % 3600) / 60);
    var s = Math.floor((v % 3600) % 60).toFixed(0);
    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? (m < 10 ? "0" + m : m) + ":" : "00:";
    var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    return hDisplay + mDisplay + sDisplay;
};

export default formatSeconds;